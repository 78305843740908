import React from 'react'
import './b1_race_enroll_info.css'
import LayoutB1 from "../../components/layout-b1"
import { navigate } from "@reach/router"



export default class EnrollInfo extends React.Component{

  routePush=()=>{
    navigate('/demos/themeB1/race_enroll_detail')
  }
  render() {
    const color='#fc9816'
    return (
      <LayoutB1 location={this.props.location} >
        <div className="enroll-info-container">
          <div className="info-title">报名详情</div>
          <div className="enroll-state">已成功报名</div>
          <div className="event">太湖马拉松-全马</div>
          <div className="border-line"></div>
          <div className="racer-enroll-info">
            <div className="racer-enroll-info-title">
              <div className="title-vertical-line"></div>
              <div>报名信息</div>
              <div onClick={this.routePush} style={{marginLeft:'20px',color:color,textDecoration:'underline'}}>查看详细报名信息</div>
            </div>
            <div>
              <div className="info-item-left">
                <div className="info-line">
                  <div className="item1">姓名:</div>
                  <div className="item2">张迈</div>
                </div>
                <div className="info-line">
                  <div className="item1">参赛号:</div>
                  <div className="item2">暂无</div>
                </div>
                <div className="info-line">
                  <div className="item1">枪声成绩:</div>
                  <div className="item2">暂无</div>
                </div>
              </div>
              <div  className="info-item-right">
                <div className="info-line">
                  <div className="item1">证件号码:</div>
                  <div className="item2">130888199802092223</div>
                </div>
                <div className="info-line">
                  <div className="item1">报名确认函:</div>
                  <div className="item2">暂无</div>
                </div>
                <div className="info-line">
                  <div className="item1">成绩证书:</div>
                  <div className="item2">暂无</div>
                </div>
              </div>
              <div style={{clear:'both'}}></div>
            </div>
          </div>
          <div className="border-line"></div>
          <div className="order-info">
            <div className="order-info-title">
              <div className="title-vertical-line"></div>
              <div >订单信息</div>
            </div>
            <div>
              <div className="info-item-left">
                <div className="info-line">
                  <div className="item1">订单信息:</div>
                  <div className="item2">xxxxxxxxxxxxxxxx</div>
                </div>
                <div className="info-line">
                  <div className="item1">下单时间:</div>
                  <div className="item2">2019-11-08 10:30</div>
                </div>
                <div className="info-line">
                  <div className="item1">支付时间:</div>
                  <div className="item2">2019-11-08 10:35</div>
                </div>
                <div className="info-line">
                  <div className="item1">支付订单号:</div>
                  <div className="item2">--</div>
                </div>
              </div>
              <div className="info-item-right">
                <div className="info-line">
                  <div className="item1">订单金额:</div>
                  <div className="item2">￥130</div>
                </div>
                <div className="info-line">
                  <div className="item1">订单状态:</div>
                  <div className="item2">报名成功</div>
                </div>
                <div className="info-line">
                  <div className="item1">支付方式:</div>
                  <div className="item2">支付宝</div>
                </div>
                <div className="info-line">
                  <div className="item1"></div>
                  <div className="item2"></div>
                </div>
              </div>
              <div style={{clear:'both'}}></div>
            </div>
          </div>
        </div>
      </LayoutB1>

    )


  }
}
